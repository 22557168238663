import * as fal from "@fortawesome/pro-light-svg-icons";
import * as fab from "@fortawesome/free-brands-svg-icons";

/**
* @returns Icons from fontawesome
*/
const icons = {
    home: fal.faHomeLgAlt,
    exchange: fal.faExchange,
    farm: fal.faHandHoldingSeedling,
    pig: fal.faPig,
    scale: fal.faBalanceScale,
    shield: fal.faShieldAlt,
    hamburgerMenu: fal.faBars,
    badgeCheck: fal.faBadgeCheck,
    search: fal.faSearch,
    faceConfounded: fal.faFaceConfounded,
    fire: fal.faFire,
    cubes: fal.faCubes,
    triangle: fal.faTriangle,
    chart: fal.faChartWaterfall,
    virus: fal.faVirus,
    objectsColumn: fal.faObjectsColumn,
    minus: fal.faMinus,
    plus: fal.faPlus,
    infoCircle: fal.faInfoCircle,
    times: fal.faTimes,
    twitter: fab.faTwitter,
    telegram: fab.faTelegram,
    bookBlank: fal.faBookBlank,
    chevronDown: fal.faChevronDown,
    coin: fal.faCoin
}

export default icons;