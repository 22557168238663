import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    hide: false,
    pid: 0,
    risk: 1,
    lpSymbol: 'USDC-WETH',
    lpAddresses: {
      137: '0x853ee4b2a13f8a742d64c8f088be7ba2131f670d',
      80001: '',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
      80001: '',
    },
    quoteTokenSymbol: QuoteToken.WETH,
    quoteTokenAdresses: contracts.weth,
    lpLink: 'https://info.quickswap.exchange/pair/0x853ee4b2a13f8a742d64c8f088be7ba2131f670d'
  },
  {
    hide: false,
    pid: 1,
    risk: 1,
    lpSymbol: 'WMATIC-USDC',
    lpAddresses: {
      137: '0x6e7a5fafcec6bb1e78bae2a1f0b612012bf14827',
      80001: '',
    },
    tokenSymbol: 'WMATIC',
    tokenAddresses: {
      137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      80001: '',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    lpLink: 'https://info.quickswap.exchange/pair/0x6e7a5fafcec6bb1e78bae2a1f0b612012bf14827'
  },
  {
    hide: false,
    pid: 2,
    risk: 1,
    lpSymbol: 'VBTC-WETH',
    lpAddresses: {
      137: '0x946b849a44c0d543af8a3a2f664eb47e033ec691',
      80001: '',
    },
    tokenSymbol: 'VBTC',
    tokenAddresses: {
      137: '0x1489f4f7e3a80a0ad9fa23c39d2e8af818204ce9',
      80001: '',
    },
    quoteTokenSymbol: QuoteToken.WETH,
    quoteTokenAdresses: contracts.weth,
    lpLink: 'https://charts.cometh.io/pair/0x946b849a44c0d543af8a3a2f664eb47e033ec691'
  },
  {
    hide: false,
    isTokenOnly: true,
    pid: 3,
    risk: 1,
    lpSymbol: 'PYM',
    lpAddresses: {
      137: '0xb6f960aba929b2ff464b6958ebcbaf26f2dab20f',
      80001: '',
    },
    tokenSymbol: 'PYM',
    tokenAddresses: {
      137: '0xAAB1e09ccfeE6d40F18DB46293D14B30Dd1073bE',
      80001: '',
    },
    quoteTokenSymbol: QuoteToken.WMATIC,
    quoteTokenAdresses: contracts.wmatic,
    lpLink: 'https://info.quickswap.exchange/token/0xaab1e09ccfee6d40f18db46293d14b30dd1073be'
  },
  {
    hide: false,
    isTokenOnly: true,
    pid: 4,
    risk: 1,
    lpSymbol: 'VBTC',
    lpAddresses: {
      137: '0x8ff2e4cf88f6891212ae57e306f244a8cee71ea9', // VBTC-WETH
      80001: '',
    },
    tokenSymbol: 'VBTC',
    tokenAddresses: {
      137: '0x1489f4f7e3a80a0ad9fa23c39d2e8af818204ce9',
      80001: '',
    },
    quoteTokenSymbol: QuoteToken.WETH,
    quoteTokenAdresses: contracts.weth,
    lpLink: 'https://charts.cometh.io/token/0x1489f4f7e3a80a0ad9fa23c39d2e8af818204ce9'
  },
  {
    hide: false,
    isTokenOnly: true,
    pid: 5,
    risk: 1,
    lpSymbol: 'WETH',
    lpAddresses: {
      137: '0x853ee4b2a13f8a742d64c8f088be7ba2131f670d',
      80001: '',
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      137: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
      80001: '',
    },
    quoteTokenSymbol: QuoteToken.WETH,
    quoteTokenAdresses: contracts.weth,
    lpLink: 'https://info.quickswap.exchange/token/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619'
  },
  {
    hide: false,
    pid: 6,
    risk: 1,
    lpSymbol: 'PYM-MATIC',
    lpAddresses: {
      137: '0xb6f960aba929b2ff464b6958ebcbaf26f2dab20f',
      80001: '',
    },
    tokenSymbol: 'PYM',
    tokenAddresses: {
      137: '0xAAB1e09ccfeE6d40F18DB46293D14B30Dd1073bE',
      80001: '',
    },
    quoteTokenSymbol: QuoteToken.WMATIC,
    quoteTokenAdresses: contracts.wmatic,
    lpLink: 'https://info.quickswap.exchange/pair/0xb6f960aba929b2ff464b6958ebcbaf26f2dab20f'
  }
]

export default farms
