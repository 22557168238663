import React, { useCallback, useState } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemIcon } from '@material-ui/core'
import { getImage, isStandbyMode, truncateFromCenter } from 'helpers/SharedFunctions'
import { useHistory, useLocation } from 'react-router';
import { useWalletModal } from '@pancakeswap-libs/uikit'
import { getBalanceNumber } from 'utils/formatBalance';
import { getPYMAddress } from 'utils/addressHelpers';

import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import icons from 'helpers/icons';
import useTokenBalance from 'hooks/useTokenBalance';
import logoIcon from '../../assets/images/polymon_logo-icon.svg'
import config from './config'
import useI18n from '../../hooks/useI18n'
import RugDocReview from '../../assets/images/rugdoc-review-badge.svg'

const Menu = (props) => {
  const { account, connect, reset } = useWallet()
  const [mobileOpen, setMobileOpen] = useState(false);
  const [docsCollapse, setDocsCollapse] = useState(false);
  const [chartsCollapse, setChartsCollapse] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { onPresentConnectModal } = useWalletModal(connect, reset)
  const TranslateString = useI18n()
  const pymBalance = getBalanceNumber(useTokenBalance(getPYMAddress()))

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const selectedPage = useCallback((href: any) => {
    if (location.pathname === href) return true

    return false;
  }, [location.pathname])

  const drawer = (
    <div className='drawer-content'>
      <div className='drawer-logo'>
        <img src={getImage('PYM_LOGO')} alt="PYM_LOGO" />
      </div>
      <List>
        {config.map((m) => (
          <ListItem button key={m.label} selected={selectedPage(m.href)} onClick={() => {
            if (mobileOpen) setMobileOpen(false);
            return m.href ? history.push(m.href) : window.open(m.customLink, '_blank')
          }}>
            <ListItemIcon><FontAwesomeIcon icon={m.icon} /></ListItemIcon>
            <ListItemText primary={m.label} />
          </ListItem>
        ))}
        <ListItem button onClick={() => setDocsCollapse(!docsCollapse)}>
          <ListItemIcon><FontAwesomeIcon icon={icons.bookBlank} /></ListItemIcon>
          <ListItemText primary='Docs' />
          <ListItemIcon className={`${docsCollapse ? 'chevron-in' : 'chevron-out'}`}><FontAwesomeIcon icon={icons.chevronDown} /></ListItemIcon>
        </ListItem>
        <Collapse in={docsCollapse} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => window.open('https://nerd-finance.gitbook.io/polymon-finance/', '_blank')}>
              <ListItemIcon> </ListItemIcon>
              <ListItemText primary='Gitbook' />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={() => setChartsCollapse(!chartsCollapse)}>
          <ListItemIcon><FontAwesomeIcon icon={icons.chart} /></ListItemIcon>
          <ListItemText primary='Charts' />
          <ListItemIcon className={`${chartsCollapse ? 'chevron-in' : 'chevron-out'}`}><FontAwesomeIcon icon={icons.chevronDown} /></ListItemIcon>
        </ListItem>
        <Collapse in={chartsCollapse} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => window.open('https://polygon.poocoin.app/tokens/0xaab1e09ccfee6d40f18db46293d14b30dd1073be', '_blank')}>
              <ListItemIcon> </ListItemIcon>
              <ListItemText primary='Poocoin' />
            </ListItem>
            <ListItem button onClick={() => window.open('https://www.dextools.io/app/quickswap/pair-explorer/0xb6f960aba929b2ff464b6958ebcbaf26f2dab20f', '_blank')}>
              <ListItemIcon> </ListItemIcon>
              <ListItemText primary='Dextools' />
            </ListItem>
          </List>
        </Collapse>
        <div className='reviewed-icon-wrap'>
          <div className='reviewed-icon'>
            <a href="https://rugdoc.io/project/polymon/" target='_blank' rel="noreferrer">
              <img src={RugDocReview} alt="RUG DOC REVIEW" />
            </a>
          </div>
        </div>
      </List>
    </div >
  );

  return (
    <div className='menu-wrapper'>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}>
            <FontAwesomeIcon icon={icons.hamburgerMenu} />
          </IconButton>
          <Button disabled={isStandbyMode()} variant="contained" className='main-btn' onClick={isStandbyMode() ? null : onPresentConnectModal}>{isStandbyMode() ? 'Standby' : account && truncateFromCenter(account) || TranslateString(612, 'Connect')}</Button>
        </Toolbar>
      </AppBar>
      <nav aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor='left'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}>
            {drawer}
            <div className='nav-btm'>
              <div className='nav-btm-item'>
                <img src={getImage('LOGO')} alt='PYM' />
                <p>{pymBalance.toFixed(3)}</p>
              </div>
              <div className='menu-socials'>
                <div className='social-icon twitter'><a href="https://twitter.com/PolymonFinance" target="_blank" rel="noreferrer"> <FontAwesomeIcon icon={icons.twitter} /></a></div>
                <div className='social-icon telegram'><a href="https://t.me/PolymonFinance" target="_blank" rel="noreferrer"> <FontAwesomeIcon icon={icons.telegram} /></a></div>
              </div>
            </div>
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            variant="permanent"
            open>
            {drawer}
          </Drawer>
          <div className='nav-btm'>
            <div className='nav-btm-item'>
              <img src={getImage('LOGO')} alt='PYM' />
              <p>{pymBalance.toFixed(3)}</p>
            </div>
            <div className='menu-socials'>
              <div className='social-icon twitter'><a href="https://twitter.com/PolymonFinance" target="_blank" rel="noreferrer"> <FontAwesomeIcon icon={icons.twitter} /></a></div>
              <div className='social-icon telegram'><a href="https://t.me/PolymonFinance" target="_blank" rel="noreferrer"> <FontAwesomeIcon icon={icons.telegram} /></a></div>
            </div>
          </div>
        </Hidden>
      </nav>
    </div>
  )
}

export default Menu
