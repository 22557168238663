import PYM_LOGO from '../assets/images/pym-logo-dark-finance.svg';
import LOGO from '../assets/images/token-icon.png';
import BUSD from '../assets/images/busd.png';

// eslint-disable-next-line import/prefer-default-export
export const getImage = (name: string) => {
    switch (name) {
        case 'PYM_LOGO':
            return PYM_LOGO;
        case 'LOGO':
            return LOGO;
        case 'BUSD':
            return BUSD;
        default:
        // code block
    }
    return '';
}

export const truncateFromCenter = (value: string): string => {
    return `${value.substring(0, 15)}...${value.substring(value.length - 5, value.length)}`
}

export const isStandbyMode = () => {
    if(process.env.REACT_APP_STANDBY === 'true') return true
    return false;
}