import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from "@emotion/react";

const PageLoader: React.FC = () => {

  const override = css`
    height: 48px;
    width: 48px;
    border-color: var(--main-color);
    border-bottom-color: transparent;
  `

  return (
    <div className='page-wrapper page-center loading-wrapper'>
      <ClipLoader css={override} />
    </div>
  )
}

export default PageLoader
