export default {
  pym: {
    137: '0xAAB1e09ccfeE6d40F18DB46293D14B30Dd1073bE',
    80001: '',
  },
  pymFarm: {
    137: '0x1cB0921E4Ae7F6b409fd85368f7B5faDa6a7c5d9',
    80001: '',
  },
  wbnb: {
    137: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    80001: '',
  },
  lottery: {
    137: '',
    80001: '',
  },
  lotteryNFT: {
    137: '',
    80001: '',
  },
  mulltiCall: {
    137: '0x017bB454E8f4CE51D07460cA2e20AB509a152c1a',
    80001: '',
  },
  test: {
    137: '0x0eeed4a2de0c8df2e38d8c82d6ce1b178dba0845',
    80001: '',
  },
  usdc: {
    137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    80001: '',
  },
  wmatic: {
    137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    80001: '',
  },
  vbtc: {
    137: '0x1489f4f7e3a80a0ad9fa23c39d2e8af818204ce9',
    80001: '',
  },
  weth: {
    137: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    80001: '',
  }
}
