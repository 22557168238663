import icons from 'helpers/icons'

const config: any[] = [
  {
    label: 'Dashboard',
    icon: icons.objectsColumn,
    href: '/',
  },
  {
    label: 'Farms',
    icon: icons.farm,
    href: '/farms',
  },
  {
    label: 'Nests',
    icon: icons.pig,
    href: '/nests',
  },
  {
    label: 'Buy PYM',
    icon: icons.coin,
    href: '',
    customLink: 'https://quickswap.exchange/#/swap?outputCurrency=0xAAB1e09ccfeE6d40F18DB46293D14B30Dd1073bE'
  },
]

export default config
